<template>
  <div class="grid-container" :key="trigger">
    <Navbar class="navbar" />

    <div class="calendar">
      <MonthSelector
        v-if="ready"
        class="dateSelector"
        :month="selectedMonth"
        :year="selectedYear"
        @monthChanged="monthChanged"
        @yearChanged="yearChanged"
      />

      <CalendarGrid
        v-if="ready"
        class="calendarGrid"
        :days="events"
        :month="selectedMonth"
        :year="selectedYear"
        @selectEvent="displayDetails"
        @changeDayEvent="getDayEvent"
      />
    </div>

    <EventDetails
      class="details"
      :tags="allTags"
      :event="event"
      :selectedDay="selectedDay"
      @eventDeleted="eventDeletionHandler"
      @getNewEvent="updateData"
      @selectEvent="displayDetails"
    />
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import Navbar from "@/components/Navbar";
import MonthSelector from "@/components/calendar/MonthSelector";
import CalendarGrid from "@/components/calendar/CalendarGrid";
import EventDetails from "@/components/calendar/EventDetails";

export default {
  name: "Calendar",
  components: { Navbar, CalendarGrid, MonthSelector, EventDetails },
  data() {
    return {
      selectedMonth: 1,
      selectedYear: 2020,
      selectedDay: -1,
      trigger: 0,
      events: [
        // 2D array, size [28-31,n]
        // 2nd dimension has array of events for that day
      ],
      event: {}, // Currently selected event
      allTags: [],
      ready: false,
    };
  },
  async mounted() {
    // Set selectedMonth and year to current month and year
    let d = new Date();
    this.selectedMonth = d.getMonth() + 1;
    this.selectedYear = d.getFullYear();

    await this.getData();
    await this.getTags();
    this.ready = true;

    if (this.$route.query.eventId !== undefined) {
      this.getEvent();
    }
  },
  computed: {
    ...mapStores(useUserStore),
  },
  methods: {
    getDayEvent(day) {
      this.selectedDay = day;
    },
    monthChanged(i) {
      this.selectedMonth = i;
      this.getData();
    },
    yearChanged(i) {
      this.selectedYear = i;
      this.getData();
    },

    async updateData(data) {
      await this.getData();
      this.event.title = data.title;
      this.event.description = data.description;
      this.event.date_when = data.date_when;
      this.trigger++;
    },

    async eventDeletionHandler() {
      await this.getData();
      this.trigger++;
    },

    async getTags() {
      let response = await this.$api.getTags();
      if (response.success) {
        this.allTags = response.tags;
      }
    },
    async getData() {
      let response = await this.$api.getMonthData(
        this.selectedYear,
        this.selectedMonth,
        this.userStore.user.id,
        this.userStore.accessToken
      );

      if (response.success === true) {
        this.events = response.events;
      }
    },

    async getEvent() {
      let response = await this.$api.getSingleEvent(+this.$route.query.eventId);
      if (response.success) {
        this.event = response.event[0];
        if (this.event.admin === 1) {
          this.event.name_group = "Admin";
        } else {
          this.event.name_group = this.event.name;
        }
      }
    },

    displayDetails(event) {
      this.event = event;
      window.scrollTo(0, document.body.scrollHeight);
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: 0.1fr 1fr 0.1fr;
  grid-template-rows: auto auto auto;
  gap: 20px 20px;
  grid-template-areas:
    "navbar navbar navbar"
    ". calendar ."
    ". details .";
}
.navbar {
  grid-area: navbar;
}
.calendar {
  grid-area: calendar;

  background-color: var(--dark);
  border-radius: 15px;
  padding: 20px;
}
.details {
  position: absolute;
  z-index: 20;
  grid-area: details;
  margin-bottom: 20px;
}

@media screen and (max-width: 700px) {
  .grid-container {
    display: grid;
    grid-template-areas:
      "navbar navbar navbar"
      "calendar calendar calendar"
      "details details details";
  }
  .calendar {
    margin: 0 10px;
  }
  .details {
    h1 {
      font-size: 24px;
    }
  }
}
</style>
